import React, { useRef, useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Stage, Layer, Line } from 'react-konva'
import classNames from 'classnames'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'

let useClickOutside = handlerMouseUp => {
  let domNode = useRef()

  useEffect(() => {
    let maybeHandlerMouseUp = event => {
      if (!domNode.current.contains(event.target)) {
        handlerMouseUp()
      }
    }

    document.addEventListener('mouseup', maybeHandlerMouseUp)

    return () => {
      document.removeEventListener('mouseup', maybeHandlerMouseUp)
    }
  })

  return domNode
}

const ModalToSign = ({ modalTitle, onClose, onValidate }) => {
  const [grabbing, setGrabbing] = useState(false)
  const [lines, setLines] = useState([])
  const isDrawing = useRef(false)

  const handleTouchStart = e => {
    console.log('handleTouchStart')
    isDrawing.current = true
    if (e) {
      const pos = e.target.getStage().getPointerPosition()
      setLines([...lines, { tool: 'pen', points: [pos.x, pos.y, pos.x, pos.y] }])
    }
  }

  const handleTouchMove = e => {
    console.log('handleTouchMove')

    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()
    let lastLine = lines[lines.length - 1]
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y])

    // replace last
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  const handleTouchEnd = e => {
    console.log('handleTouchEnd')

    isDrawing.current = false
  }

  const handleMouseDown = e => {
    console.log('handleMouseDown')

    isDrawing.current = true
    if (e) {
      const pos = e.target.getStage().getPointerPosition()
      setLines([...lines, { tool: 'pen', points: [pos.x, pos.y, pos.x, pos.y] }])
    }
  }

  const handleMouseMove = e => {
    console.log('handleMouseMove')

    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()
    let lastLine = lines[lines.length - 1]
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y])

    // replace last
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  const handleMouseUp = () => {
    console.log('handleMouseUp')
    isDrawing.current = false
  }

  const validate = () => {
    const canvasTag = document.getElementsByTagName('canvas')
    const context = canvasTag[0].getContext('2d')
    const canvas = context.canvas
    const imageBase64 = canvas.toDataURL()

    onValidate(imageBase64)
    onClose()
  }

  let domNode = useClickOutside(handleMouseUp)

  return (
    <ModalWithBackdrop
      show={true}
      size="modal-xl"
      close={onClose}
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{modalTitle}</h5>
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body" ref={domNode}>
        <Stage
          width={window.innerWidth * 0.77}
          height={window.innerHeight * 0.77}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
        >
          <Layer>
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke="#000000"
                strokeWidth={8}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation="source-over"
              />
            ))}
          </Layer>
        </Stage>
      </div>
      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-warning" onClick={() => setLines([])}>
          <i className="fa fa-eraser" aria-hidden="true"></i> Effacer
        </button>
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          Fermer
        </button>
        <button className="btn btn-primary" onClick={validate}>
          Valider
        </button>
      </div>
    </ModalWithBackdrop>
  )
}

const Signature = ({ inputData, formInputs, PartyInvolvedStore: { findName } }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('Signer')

  let involvedPartyField = null
  formInputs.forEach(input => {
    if (input.name === inputData.signForField) {
      involvedPartyField = input
    }
  })

  useEffect(() => {
    if (involvedPartyField.value) {
      setModalTitle(findName(involvedPartyField.value) || 'Signer')
    }
  }, [involvedPartyField.value, findName])

  return (
    <div className="form-group row no-gutters">
      <div className="col">
        {!showModal && (
          <button className="btn btn-primary" onClick={() => setShowModal(true)}>
            {inputData.value ? 'Modifier la signature' : 'Signer'}
          </button>
        )}
        {showModal && (
          <ModalToSign
            modalTitle={modalTitle}
            onClose={() => setShowModal(false)}
            onValidate={value => inputData.setProperty('value', value)}
          />
        )}
        {inputData.value && (
          <img className="signature-thumbnail ml-4" alt="signature" src={inputData.value} />
        )}
      </div>
    </div>
  )
}

export default inject('PartyInvolvedStore')(observer(Signature))
